















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import GameHud from '@/components/GameHud.vue'
import GameMap from '@/components/GameMap.vue'
import GameVideo from '@/components/GameVideo.vue'
import PasswordEntry from '@/components/PasswordEntry.vue'
import Fanfair from '@/components/Fanfair.vue'
import Flair from '@/components/Flair.vue'
import Curtain from '@/components/Curtain.vue'

export default Vue.extend({
  name: 'AquaTheater',
  components: {
    GameHud,
    GameMap,
    GameVideo,
    PasswordEntry,
    Fanfair,
    Flair,
    Curtain
  },
  props: {
  },
  data () {
    return {
      curtainActive: true,
      curtainHeading: 'Aqua Theater',
      exerciseWindow: null,
      showFanfair: false
    }
  },
  computed: {
    ...mapGetters([
      'team',
    ]),
    stageVideoCompleted () {
      return (this.team.ready && this.team.completedAquaTheaterVideo)
    },
    stageCompleted () {
      return (this.team.ready && this.team.completedAquaTheater)
    },
  },
  methods: {
    ...mapActions([
      'timerStart',
      'timerStop',
      'completeStageVideo',
      'completeStage',
    ]),
    handleHudClick (action: string) {
      if (typeof this[action] === 'function') {
        this[action]()
      }
    },
    showMap () {
      this.$refs.gameMap.show()
    },
    goto (loc: string, heading: string, delay: number) {
      console.log('Maproom.goto')
      this.curtainHeading = heading
      this.curtainActive = true
      window.setTimeout(()=>{
        this.$router.push(loc)
      }, delay)
      this.timerStop()
    },
    onCurtainClick () {
      this.curtainActive = false
    },
    startExercise () {
      this.exerciseWindow = window.open(
        '/outlook/index.php?d=1fromname',
        '1fromname',
        'popup,top=0,left=0,height='+window.screen.availHeight+',width='+window.screen.availWidth
      );
      this.$refs.passwordEntry.show()
    },
    completeExercise () {
      this.exerciseWindow.close()
      this.completeStage('AquaTheater')
      this.timerStop()
    },
    completeVideo () {
      this.completeStageVideo('AquaTheater')
      if (this.team.seenFindLaptopHint<2) this.$refs.flair.show()
      this.timerStart('AquaTheater')
    }
  },
  mounted () {
    this.$store.dispatch('updateMyTeam', {lastLocation: 'Aqua Theater'})
    window.setTimeout(()=>{
      this.curtainActive = false
      if (!this.stageVideoCompleted) this.$refs.video.show()
      else if (this.stageCompleted) this.$refs.fanfair.show()
      else {
        if (this.team.seenFindLaptopHint<2) this.$refs.flair.show()
        this.timerStart('AquaTheater')
      }
    }, 500)
  },
  watch: {
    stageCompleted () {
      if (this.stageCompleted) {
        this.$refs.fanfair.show()
        window.setTimeout(this.showMap, 5000)
      }
    }
  }
});
